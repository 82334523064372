/* src/Navbar.module.css */

.navbar {
  background: #f9f9f9;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  border-bottom: 1px solid #33333368;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 1000;
  opacity: 1;
  transform: translateY(0);
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: slideDown 0.3s ease-in-out forwards;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.logo {
  width: 90px !important;
  height: 70px !important;
  object-fit: contain;
}

.navbarContainer {
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  max-width: 1500px;
  padding: 0 24px;
}

.navbarLogo {
  color: #ff6b81;
  justify-self: start;
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  background-color: #433f3f;
  align-items: center;
  border-radius: 50%;
}

.menuIcon {
  display: none;
  color: #ff6b81;
}

.navMenu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
}

.navItem {
  height: 40px;
}

.navLinks {
  color: #000;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  transition: all 0.5s linear;
}

.navLinks:hover {
  color: #3333338f;
  letter-spacing: 3px;
}

.active {
  background-color: #ff6b81;
  color: #ffff;
  border-radius: 5rem;
  padding: 4px 15px;
}

/* Mobile styles */
@media screen and (max-width: 960px) {
  .menuIcon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #ff6b81;
  }

  .active {
    background-color: #fff;
    color: #929AAB;
    border-radius: 0;
    padding: 10px 15px;
  }

  .navMenu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    background: #333;
    gap: 2rem;
  }

  .navMenu.active {
    background: transparent;
    backdrop-filter: blur(10px);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .navItem {
    width: 100%;
  }

  .navLinks {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .navLinks:hover {
    background-color: #ff6b81;
    border-radius: 0;
  }
}
