
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
body,html{
  margin: 0;
  padding: 0;

  font-family: 'Poppins',sans-serif;
  width: 100%;
  overflow-x: hidden;

}
*,*::after,*::before {
  box-sizing: border-box;
}
body{
  background: #F7F7F7;
}

a {
  color: #fff;
}
*, *::after, *::before { 
  box-sizing: border-box;

}